import parse, { attributesToProps, domToReact } from 'html-react-parser';

import * as Link from 'components/Link';

type Options = {
  linkConfig: Pick<
    Link.Props,
    'color' | 'size' | 'weight' | 'externalHrefDefaultTarget' | 'onClick'
  >;
};

const defaultOptions: Options = { linkConfig: { size: 'inherit' } };

export function parseHTML(
  html: string,
  { linkConfig }: Options = defaultOptions,
) {
  return parse(html, {
    replace: domNode => {
      if ('attribs' in domNode && domNode.tagName === 'a') {
        const props = attributesToProps(domNode.attribs);

        return (
          <Link.Component
            href={props['href']}
            target={props['target']}
            rel={props['rel']}
            color="accent-2"
            weight="normal"
            {...linkConfig}
          >
            {domToReact(domNode.children, domNode.attribs)}
          </Link.Component>
        );
      }
    },
  });
}
