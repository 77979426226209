import { VariantSelectionQuestion } from 'features/project/Constructor/subfeatures';
import { IDProvider } from 'services';
import { makeFormElementState } from 'utils/FormState';
import { AnswerVariant } from 'utils/business';

import { StateInstance } from '../types';

function makeVariantProperties(
  stateInstanceVariant: AnswerVariant.AnswerVariant,
  getID: () => string,
): VariantSelectionQuestion.VariantProperties {
  const image = stateInstanceVariant.image.units.value.getState();
  return {
    id: getID(),
    explanation:
      stateInstanceVariant.explanation.formElementState.units.value.getState(),
    text: stateInstanceVariant.text.formElementState.units.value.getState(),
    imageURL: image?.thumb[0].url ?? null,
  };
}

export function useModel(
  stateInstance: StateInstance,
): VariantSelectionQuestion.Model {
  const hasMultipleChoice =
    stateInstance.hasMultipleChoice.units.value.useState();
  const getVariantID = IDProvider.useGetID('variant');

  const variants = stateInstance.variants.useState();

  return hasMultipleChoice
    ? {
        kind: 'multiple',
        elementModels: variants.map(
          (x): VariantSelectionQuestion.MultipleChoiceElementModel => {
            return x.isOwn.units.value.getState()
              ? {
                  kind: 'own',
                  checkedState: makeFormElementState<boolean>(false),
                  properties: makeVariantProperties(x, getVariantID),
                  textState: makeFormElementState(''),
                }
              : {
                  kind: 'regular',
                  checkedState: makeFormElementState<boolean>(false),
                  properties: makeVariantProperties(x, getVariantID),
                };
          },
        ),
      }
    : {
        kind: 'single',
        display: stateInstance.displayKind.units.value.getState(),
        valueState:
          makeFormElementState<VariantSelectionQuestion.SingleChoiceElementModel | null>(
            null,
          ),
        elementModels: variants.map(
          (x): VariantSelectionQuestion.SingleChoiceElementModel => {
            return x.isOwn.units.value.getState()
              ? {
                  kind: 'own',
                  textState: makeFormElementState<string>(''),
                  properties: makeVariantProperties(x, getVariantID),
                }
              : {
                  kind: 'regular',
                  properties: makeVariantProperties(x, getVariantID),
                };
          },
        ),
      };
}
