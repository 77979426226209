import React, { useRef, useCallback } from 'react';

import { Page } from 'services';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as HeaderResizeObserver from './HeaderResizeObserver';
import './style.scss';
import { WidgetFullscreenControl } from './subfeatures';

const b = block('fullscreen-widget-container');

type Props = {
  Content: React.VFC;
  Header?: React.VFC;
  Controls?: React.VFC;
  fullScreenUnit: PrimaryStateUnit<boolean>;
};

function FullScreenWidgetContainer({
  Content,
  Header,
  Controls,
  fullScreenUnit,
}: Props) {
  const rootRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLHeadingElement>(null);

  const fullScreen = fullScreenUnit.useState();

  const handleFullscreenOffClick = useCallback(() => {
    fullScreenUnit.setState(false);
  }, [fullScreenUnit]);

  Page.useSetScroll(fullScreen);

  return (
    <div className={b()} ref={rootRef}>
      <header className={b('header')} ref={headerRef}>
        <div className={b('header-left-part')}>{Header && <Header />}</div>
        <div className={b('header-right-part')}>
          {Controls && <Controls />}
          <WidgetFullscreenControl.Component
            variant="off"
            onClick={handleFullscreenOffClick}
          />
        </div>
      </header>
      <main className={b('main')}>
        <Content />
      </main>
      <HeaderResizeObserver.Component
        containerRef={rootRef}
        headerRef={headerRef}
      />
    </div>
  );
}

export const Component = React.memo(FullScreenWidgetContainer);
