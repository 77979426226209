import React, { useCallback } from 'react';

import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { TextEditor } from 'features/project/Constructor/subfeatures';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { projectDescriptionState } from '../../units/inputState';
import './style.scss';

const b = block('project-description');

type Props = {};

function ProjectDescription({}: Props) {
  const { saveProject } = useRequiredContext(ProjectWriteContext);

  const handleBlur = useCallback(() => {
    saveProject();
  }, [saveProject]);

  return (
    <div className={b()}>
      <TextEditor.Component
        formElementState={projectDescriptionState}
        errorRows={1}
        onBlur={handleBlur}
      />
    </div>
  );
}

export const Component = React.memo(ProjectDescription);
