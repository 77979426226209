import { useCallback } from 'react';

import * as I18n from 'services/I18n';
import { projectLangsUnit } from 'shared/stateUnits';
import * as TS from 'types';
import * as M from 'types/serverModels';

import { activeLangStateUnit } from './activeLangStateUnit';

export function getMultilingTranslation(
  lang: TS.Language,
  str: M.MultilingString,
  langs?: TS.Language[],
) {
  const translation = str.find(x => x.lang === lang)?.value;

  if (typeof translation === 'string') {
    return translation;
  }

  if (Array.isArray(langs)) {
    return str.find(x => x.lang === langs[0])?.value || '';
  }

  return str[0]?.value || '';
}

export function getOptionalMultilingTranslation(
  lang: TS.Language,
  str?: M.MultilingString,
  langs?: TS.Language[],
) {
  return str ? getMultilingTranslation(lang, str, langs) : '';
}

export function useGetMultilingTranslation(langs?: TS.Language[]) {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (str: M.MultilingString) => getMultilingTranslation(lang, str, langs),
    [lang, langs],
  );
}

export function useGetOptionalMultilingTranslation(langs?: TS.Language[]) {
  const lang = activeLangStateUnit.useState();

  return useCallback(
    (str?: M.MultilingString) =>
      getOptionalMultilingTranslation(lang, str, langs),
    [lang, langs],
  );
}

export function getMultilingTranslationForCurrentLang(str: M.MultilingString) {
  return getMultilingTranslation(activeLangStateUnit.getState(), str);
}

export function makeGetMultilingTranslation(
  lang: TS.Language,
  langs?: TS.Language[],
) {
  return (str: M.MultilingString) => getMultilingTranslation(lang, str, langs);
}

export function makeGetOptionalMultilingTranslation(lang: TS.Language) {
  return (str?: M.MultilingString) =>
    getOptionalMultilingTranslation(lang, str);
}

export function useGetMultilingProjectTranslation() {
  const projectLangs = projectLangsUnit.useState();
  const t = I18n.useGetOptionalMultilingTranslation(projectLangs);

  return t;
}
