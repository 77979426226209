import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type BodyInputData = {
  /**
   * 'lang' | 'is_individual' are required
   */
  project_book: Omit<M.ProjectBook, 'uuid' | 'supervisor' | 'cdate'>;
};

type InputData = BodyInputData;

type APIOutputData = {
  project_book: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users?: M.UserXShort[];
  projects?: M.ProjectXShort[];
};

type OutputData = {
  projectBook: SetRequired<
    M.ProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >;
  users: M.UserXShortRecord;
  projects: M.ProjectXShorts;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBook: data.project_book,
    users:
      data?.users?.reduce<M.UserXShortRecord>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
    projects:
      data?.projects?.reduce<M.ProjectXShorts>(
        (acc, x) =>
          x.uuid !== undefined ? { ...acc, [x.uuid]: x } : { ...acc },
        {},
      ) || {},
  };
}

export const post = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket }, convertToSearchParams) =>
    `project_book/?${convertToSearchParams({ ticket })}`,
  'api-v2-post',
  false,
  extractResponse,
  {
    extractBodyInput: input => {
      const { ticket, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
