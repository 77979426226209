import { GeoJsonObject } from 'geojson';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  data: {
    uuid?: M.UUID;
    group?: string;
    project: M.UUID;
    answers: Record<
      M.UUID,
      number | Date | string[] | M.Image | M.Location | GeoJsonObject
    >;
    custom: Record<string, Record<M.UUID, string>>;
  };
};

type Output = {
  data: M.QuestionnaireData;
};

export const put = makeService<InputData, Output>(
  ({ data }) => `data/${data.uuid}`,
  'api-v2-put',
  false,
);
