import React from 'react';

import { block } from 'utils/classname';

import './style.scss';

const b = block('labeled-select');

type Props = {
  Select: React.FC;
  label: string;
  explanation?: string;
};

function LabeledSelect({ Select, label, explanation }: Props) {
  return (
    <div className={b()}>
      <div
        className={b('label', {
          'for-select-with-explanation': explanation !== undefined,
        })}
      >
        {label}
      </div>
      {explanation && <div className={b('explanation')}>{explanation}</div>}
      <Select />
    </div>
  );
}

export const Component = React.memo(LabeledSelect);
