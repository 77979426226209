import { FilterQuery } from 'mongodb';
import { SetRequired } from 'type-fest';

import * as M from 'types/serverModels';

import { makeService } from '../utils';

type PathInputData = {};

type QueryInputData = {
  filter?: FilterQuery<M.ProjectBook>;
  sort?: { property: keyof M.ProjectBook; direction: 'ASC' | 'DESC' };
  start?: number;
  limit?: number;
  only_count?: boolean;
};

type InputData = PathInputData & QueryInputData;

type APIOutputData = {
  project_books?: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
  count?: number;
};

type OutputData = {
  projectBooks: SetRequired<
    M.ShortProjectBook,
    'uuid' | 'lang' | 'cdate' | 'supervisors'
  >[];
  count: number;
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBooks: data.project_books || [],
    count: data.count || 0,
  };
}

export const list = makeService<InputData, APIOutputData, OutputData>(
  (inputData, convertToSearchParams) => {
    const { filter, sort, ...rest } = inputData;

    return `project_book/?${convertToSearchParams({
      ...rest,
      filter: JSON.stringify(filter),
      sort: JSON.stringify(sort),
    })}`;
  },
  'api-v2-get',
  false,
  extractResponse,
);
