import { debounce } from 'debounce';
import { XAxisOptions } from 'highcharts';
import * as R from 'ramda';
import React, { useEffect, useMemo } from 'react';

import { Chart } from 'components';
import { questionIDToQuestionTextUnit } from 'features/project/Constructor/FormWithSteps/steps/InputDataForm/units/instances';
import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import {
  axisDataLatitudeReference,
  axisDataLongtitudeReference,
  axisDataQuantityReference,
  axisDataQuestionnaireSubmitDateReference,
} from 'features/project/Constructor/i18nSharedReferences';
import { Filter } from 'features/project/Constructor/subfeatures';
import { API, I18n } from 'services';
import { makeMappingUnit } from 'utils/State';
import { AxisData } from 'utils/business';
import { getAxisDataText } from 'utils/business/AxisData';
import { useRequiredContext } from 'utils/react/RequiredContext';

import * as modals from '../../../../modals';
import { ViewProps } from '../../../../types';
import * as DatalessWidgetLayout from '../../../shared/DatalessWidgetLayout';
import { makeEmulateParams } from '../../../shared/makeEmulateParams';
import { Instance } from '../types';

function View({
  instance,
  shouldEmulateDataUnit,
  useEmulationSeed,
}: ViewProps<Instance>) {
  const callStateUnit = API.services.data.chart.useCallStateUnit();
  const callState = callStateUnit.useState();

  const palette = instance.palette.units.value.useState();

  const emulationSeed = useEmulationSeed();

  const call = API.services.data.chart.useCall(callStateUnit);

  const filterStateForRequestUnit = useMemo(
    () => Filter.makeFilterStateUnitForRequest(instance.filter),
    [instance.filter],
  );

  const settingsUnit = useMemo(() => {
    return makeMappingUnit({
      selectedChartType: instance.chartType.units.value,
      selectedCuttingQuestion: instance.cuttingQuestion.units.value,
      selectedXAxis: instance.xAxisData.units.value,
      selectedYAxis: instance.yAxisData.units.value,
      filter: filterStateForRequestUnit,
      language: I18n.activeLangStateUnit,
      shouldEmulate: shouldEmulateDataUnit,
    });
  }, [filterStateForRequestUnit, instance, shouldEmulateDataUnit]);

  const { getProjectUUID } = useRequiredContext(ConstructorConfigContext);

  useEffect(() => {
    const requestData = (
      settings: ReturnType<(typeof settingsUnit)['getState']>,
      prevSettings?: ReturnType<(typeof settingsUnit)['getState']>,
    ) => {
      const projectUUID = getProjectUUID();

      if (
        settings.selectedXAxis &&
        settings.selectedYAxis &&
        projectUUID &&
        !R.equals(settings, prevSettings) &&
        instance.filter.validate()
      ) {
        call({
          project: projectUUID,
          xVar: AxisData.makeServerXAxisDataFromModel(settings.selectedXAxis),
          yVar: AxisData.makeServerYAxisDataFromModel(settings.selectedYAxis),
          chartType: settings.selectedChartType,
          lang: settings.language,
          seriesVar:
            settings.selectedCuttingQuestion !== null
              ? AxisData.makeServerCuttingQuestionAxisDataFromModel(
                  settings.selectedCuttingQuestion,
                )
              : undefined,
          filter: Filter.makeServerFilter(settings.filter),
          emulate: makeEmulateParams(settings.shouldEmulate, emulationSeed),
        });
      }
    };

    requestData(settingsUnit.getState());

    return settingsUnit.subscribe({
      name: 'data-requester',
      callback: debounce(requestData, 500),
    });
  }, [call, emulationSeed, getProjectUUID, instance.filter, settingsUnit]);

  const DatalessView = DatalessWidgetLayout.useViewWithLayout(
    'chart',
    shouldEmulateDataUnit,
  );

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'error-modal-opener',
      callback: state => {
        const isError =
          shouldEmulateDataUnit.getState() &&
          state.kind === 'successful' &&
          state.data.series.every(x => x.data.length === 0) &&
          Object.values(filterStateForRequestUnit.getState()).every(
            x => x === null,
          );
        if (isError) {
          modals.QuestionsNotSavedOrOtherError.open();
        }
      },
    });
  }, [callStateUnit, filterStateForRequestUnit, shouldEmulateDataUnit]);

  const questionParamsUnit = useMemo(() => {
    return makeMappingUnit({
      selectedXAxis: instance.xAxisData.units.value,
      selectedYAxis: instance.yAxisData.units.value,
      selectedCuttingQuestion: instance.cuttingQuestion.units.value,
    });
  }, [instance]);

  useEffect(() => {
    return questionParamsUnit.subscribe({
      name: 'emulation-mode-canceller',
      callback: () => {
        if (shouldEmulateDataUnit.getState()) {
          shouldEmulateDataUnit.setState(false);
        }
      },
    });
  }, [questionParamsUnit, shouldEmulateDataUnit]);

  return API.renderCallState(callState, {
    successful: ({ data }) => {
      if (data.series.every(x => x.data.length === 0)) {
        return <DatalessView />;
      }

      const axisText = {
        latitude: I18n.getReferenceTextForCurrentLang(
          axisDataLatitudeReference,
        ),
        longtitude: I18n.getReferenceTextForCurrentLang(
          axisDataLongtitudeReference,
        ),
        quantity: I18n.getReferenceTextForCurrentLang(
          axisDataQuantityReference,
        ),
        questionnaireSubmitDate: I18n.getReferenceTextForCurrentLang(
          axisDataQuestionnaireSubmitDateReference,
        ),
      };

      const questionIDToQuestionText = questionIDToQuestionTextUnit.getState();

      const xAxis = instance.xAxisData.units.value.getState();
      const yAxis = instance.yAxisData.units.value.getState();
      const xAxisTitle =
        xAxis === null
          ? ''
          : getAxisDataText(xAxis, {
              ...axisText,
              questionDictionary: questionIDToQuestionText,
            });
      const yAxisTitle =
        yAxis === null
          ? ''
          : getAxisDataText(yAxis, {
              ...axisText,
              questionDictionary: questionIDToQuestionText,
            });

      const userOptions = {
        type: instance.chartType.units.value.getState(),
        xAxis: {
          title: {
            text: xAxisTitle,
          },
        },
        yAxis: {
          title: {
            text: yAxisTitle,
          },
        },
        colors: palette,
        dateXAxis:
          xAxis?.kind === 'questionnaire-submit-date' ||
          (data.xAxis as XAxisOptions)?.type === 'datetime',
      };

      const chartData = R.mergeDeepRight(data, userOptions);

      return <Chart.Component data={chartData} />;
    },
    error: () => <DatalessView />,
    initial: () => <DatalessView />,
  });
}

export const Component = React.memo(View);
