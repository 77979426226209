import { isRequired } from 'features/project/Constructor/i18nSharedReferences';
import { API } from 'services';
import * as M from 'types/serverModels';
import { makeFormElementState, makeFormSectionState } from 'utils/FormState';
import {
  makeDerivedUnit,
  makeMappingUnitFromUnit,
  makePrimaryUnit,
} from 'utils/State';
import {
  nonNull,
  nonEmptyString,
  makeSingleUnitValidator,
} from 'utils/validators';

import { AgeRangeState } from '../sections/AgeRange/types';
import { SubjectState } from '../sections/Subjects/types';

export const projectImageState = makeFormElementState<M.ImageInfo | null>(
  null,
  [nonNull(isRequired)],
);

export const annotationState = makeFormElementState<string>('', [
  nonEmptyString(isRequired),
]);

export const keywordsState = makeFormElementState<string>('', [
  nonEmptyString(isRequired),
]);

export const projectDescriptionState = makeFormElementState<string>('', [
  nonEmptyString(isRequired),
]);

export const weightState = makeFormElementState<number | null>(null);

export const perkState = makeFormElementState<M.ProjectPerk | null>(null);

const memoizedAgeRangesState: Record<string, SubjectState> = {};

export const ageRangesUnit = makeDerivedUnit(
  API.references.ages.agesCallStateUnit,
).getUnit<AgeRangeState[]>(callState => {
  switch (callState.kind) {
    case 'successful':
      return Object.values(callState.data).map((x): AgeRangeState => {
        if (memoizedAgeRangesState[x.uuid]) {
          return memoizedAgeRangesState[x.uuid];
        }
        const ageRangesState = {
          id: x.uuid,
          value: makeFormElementState(false),
          label: x.val,
        };
        memoizedAgeRangesState[x.uuid] = ageRangesState;

        return ageRangesState;
      });
    default:
      return [];
  }
});

const memoizedSubjectsState: Record<string, SubjectState> = {};

export const subjectsUnit = makeDerivedUnit(
  API.references.themes.themeRefsCallStateUnit,
).getUnit<SubjectState[]>(callState => {
  switch (callState.kind) {
    case 'successful':
      return Object.values(callState.data).map((x): SubjectState => {
        if (memoizedSubjectsState[x.uuid]) {
          return memoizedSubjectsState[x.uuid];
        }
        const subjectState = {
          id: x.uuid,
          value: makeFormElementState(false),
          label: x.val,
        };
        memoizedSubjectsState[x.uuid] = subjectState;

        return subjectState;
      });
    default:
      return [];
  }
});

export const classificationsUnit = makePrimaryUnit<M.UUID[]>([]);

export const ageRangesChecksUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(ageRangesUnit).getUnit(xs =>
    xs.map(x => x.value.units.value),
  ),
);

export const subjectsChecksUnit = makeMappingUnitFromUnit(
  makeDerivedUnit(subjectsUnit).getUnit(xs => xs.map(x => x.value.units.value)),
);

export const selectedAgeRangesUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(ageRangesUnit),
).getUnit(ageRanges => {
  return ageRanges
    .map(x => ({ selected: x.value.units.value, id: x.id }))
    .filter(x => x.selected)
    .map(x => x.id);
});

export const selectedSubjectsUnit = makeDerivedUnit(
  makeMappingUnitFromUnit(subjectsUnit),
).getUnit(subjects => {
  return subjects
    .map(x => ({ selected: x.value.units.value, id: x.id }))
    .filter(x => x.selected)
    .map(x => x.id);
});

const makeSomeCheckedValidator = () =>
  makeSingleUnitValidator((checks: boolean[]) =>
    checks.some(x => x === true)
      ? { kind: 'valid' }
      : { kind: 'invalid', messageReference: isRequired },
  );

export const ageRangesFormSectionState = makeFormSectionState(
  ageRangesChecksUnit,
  [makeSomeCheckedValidator()],
);
export const subjectsFormSectionState = makeFormSectionState(
  subjectsChecksUnit,
  [makeSomeCheckedValidator()],
);

ageRangesChecksUnit.subscribe({
  name: 'visited-updater',
  callback: state => {
    if (state.some(x => x === true)) {
      const visitedUnit = ageRangesFormSectionState.units.visited;

      if (!visitedUnit.getState()) {
        visitedUnit.setState(true);
      }
    }
  },
});

subjectsChecksUnit.subscribe({
  name: 'visited-updater',
  callback: state => {
    if (state.some(x => x === true)) {
      const visitedUnit = subjectsFormSectionState.units.visited;

      if (!visitedUnit.getState()) {
        visitedUnit.setState(true);
      }
    }
  },
});
