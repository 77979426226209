import { useMemo, useEffect, useCallback, useState } from 'react';
import { useRouteMatch } from 'react-router';

import { Preloader } from 'components';
import { API, DataPrefetch } from 'services';
import { Page } from 'services';
import * as M from 'types/serverModels';

import { ProjectPageRouteTree, routeTree } from '../../routeTree';
import * as Project from './Project';
import * as features from './features';

type Props = {};

const projectPrefetcher = DataPrefetch.makeDataPrefetcher(
  'project',
  routeTree.LANG.project.list.PROJECT.getPath() + '/:rest*',
  ({ project }) => {
    return API.services.project.get.callPromised({ uuidOrCode: project });
  },
);

function Content({}: Props) {
  const prefetchedProject = projectPrefetcher.getData();

  const [project, setProject] = useState<M.Project | null>(() => {
    if (prefetchedProject !== DataPrefetch.notFetched) {
      return prefetchedProject;
    }
    return null;
  });
  const match = useRouteMatch<{ project: string }>({ path });

  const uuidOrCode = match!.params.project;

  const ticket = API.useTicket();

  const callStateUnit = API.services.project.get.useCallStateUnit();
  const call = API.services.project.get.useCall(callStateUnit);

  const projectPageRouteTree: ProjectPageRouteTree = useMemo(() => {
    return {
      kind: 'project-list',
      tree: routeTree.LANG.project.list,
    };
  }, []);

  const handleQuestionnaireDelete = useCallback(() => {
    call({ uuidOrCode });
  }, [call, uuidOrCode]);

  useEffect(() => {
    return callStateUnit.subscribe({
      name: 'loading-the-project',
      callback: callState => {
        if (callState.kind === 'successful') {
          setProject(callState.data);
        }
      },
    });
  }, [callStateUnit]);

  useEffect(() => {
    if (projectPrefetcher.getData() !== DataPrefetch.notFetched) {
      return;
    }

    call({ uuidOrCode });
  }, [uuidOrCode, ticket, call]);

  useEffect(
    () => () => {
      projectPrefetcher.deleteData();
    },
    [ticket],
  );

  if (project === null) {
    return <Preloader.Component />;
  }

  return (
    <Project.Component
      project={project}
      projectPageRouteTree={projectPageRouteTree}
      onQuestionnaireDelete={handleQuestionnaireDelete}
    />
  );
}

const path = routeTree.LANG.project.list.PROJECT.getPath();

export const Component = Page.makePage({
  path,
  scrollTo: 'top-on-mount',
  Component: Content,
  features: Object.values(features).map(x => x.feature),
});
