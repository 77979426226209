/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from 'react';

import * as ExpansionPanel from 'components/ExpansionPanel';
import { makePrimaryUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import { ChildEntrySection } from '../../types';
import { LabelProps } from '../LabelProps';
import * as Content from './Content';
import './style.scss';

const b = block('m-main-menu-root-parent-entry');

export type Props = {
  alwaysExpanded: boolean;
  childrenSections: ChildEntrySection[];
  Label: React.FC<LabelProps>;
  className?: string;
  expansionPanelFullHeaderWidth?: boolean;
  boldChildrenEntries?: boolean;
};

const alwaysVisibleUnit = makePrimaryUnit<boolean>(true).getBridgeUnit(
  () => true,
  () => true,
  () => false,
);

function Mobile({
  childrenSections,
  className,
  Label,
  alwaysExpanded,
  expansionPanelFullHeaderWidth,
  boldChildrenEntries = false,
}: Props) {
  const visibleUnit = alwaysExpanded
    ? alwaysVisibleUnit
    : ExpansionPanel.makeVisibleUnit();

  const Header = useMemo(
    () =>
      ({
        panelExpandedUnit,
        ExpandTriggerArea,
      }: ExpansionPanel.HeaderProps) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps

        const HeaderLabel = useMemo(
          () => () => (
            <Label isActive={false} menuIsOpenUnit={panelExpandedUnit} />
          ),
          // eslint-disable-next-line react-hooks/exhaustive-deps
          [],
        );

        return (
          <ExpansionPanel.SimpleLabeledHeader.Component
            Label={HeaderLabel}
            fullWidth={expansionPanelFullHeaderWidth}
            ExpandTriggerArea={ExpandTriggerArea}
            panelExpandedUnit={panelExpandedUnit}
          />
        );
      },
    [Label],
  );

  const ContentComponent = useMemo(
    () =>
      Content.makeComponent({ childrenSections, bold: boldChildrenEntries }),
    [boldChildrenEntries, childrenSections],
  );

  return (
    <div className={classnames(b(), className)}>
      <ExpansionPanel.Component
        Header={Header}
        Content={ContentComponent}
        visibleUnit={visibleUnit}
        withAnimation
      />
    </div>
  );
}

export const Component = React.memo(Mobile) as typeof Mobile;
