import React, { useMemo } from 'react';

import { Checkbox } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import { SubjectState } from '../types';
import './style.scss';

const b = block('subject');

type Props = {
  subjectState: SubjectState;
};

function Subject({ subjectState }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  const Label = useMemo(() => {
    return Checkbox.makeLabel({
      useLabel: () => t(subjectState.label),
      size: 'text-14',
    });
  }, [subjectState.label, t]);

  return (
    <Checkbox.Component
      className={b()}
      formElementState={subjectState.value}
      Label={Label}
    />
  );
}

export const Component = React.memo(Subject);
