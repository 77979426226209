import React from 'react';

import * as M from 'types/serverModels';
import { getVideoSrc } from 'utils/business';
import { block } from 'utils/classname';

import './style.scss';

const b = block('video-player');

type Props = {
  video: M.VideoFromHosting;
};

function Content({ video }: Props) {
  return (
    <div className={b()}>
      <iframe
        className={b('iframe')}
        src={getVideoSrc(video)}
        frameBorder={0}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </div>
  );
}

export const Component = React.memo(Content);
