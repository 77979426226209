/* eslint-disable react-hooks/exhaustive-deps */
import * as R from 'ramda';
import React, {
  useState,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import { createPortal } from 'react-dom';

import { stickFixedPositionElement, getFixedPositionStyle } from 'utils/DOM';
import { usePrimaryUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import { ChildEntrySection } from '../types';
import * as ChildEntry from './ChildEntry';
import { LabelProps } from './LabelProps';
import './style.scss';

const b = block('main-menu-root-parent-entry');

export type Props = {
  Label: React.FC<LabelProps>;
  childrenSections: ChildEntrySection[];
  className?: string;
  useIsActive?(): boolean;
};

type ChildrenMode =
  | ChildrenNotRenderedMode
  | ChildrenVisibleMode
  | ChildrenHiddenMode;

type ChildrenNotRenderedMode = { kind: 'not-rendered' };
type ChildrenVisibleMode = { kind: 'visible' };
type ChildrenHiddenMode = { kind: 'hidden' };

function RootParentEntry({
  Label,
  childrenSections,
  className,
  useIsActive,
}: Props) {
  const childrenModeUnit = usePrimaryUnit<ChildrenMode>({
    kind: 'not-rendered',
  });

  const childrenMode = childrenModeUnit.useState();

  const [childrenPopupStyles, setChildrenPopupStyles] =
    useState<React.CSSProperties>();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const isActive = useIsActive === undefined ? false : useIsActive();

  const updateChildrenPopupStyles = useCallback(() => {
    if (labelRef.current === null) {
      return;
    }

    const anchorRect = labelRef.current.getBoundingClientRect();

    setChildrenPopupStyles(prev => ({
      ...prev,
      ...getFixedPositionStyle({
        anchorRect,
        margin: 30,
        defaultMaxHeight: Infinity,
      }),
    }));
  }, []);

  const handleChildEntrySelect = useCallback(() => {
    childrenModeUnit.setState({ kind: 'hidden' });
    document.body.removeEventListener('click', handleBodyClick);
  }, []);

  const makeChildrenSectionRenderer =
    (childrenSection: ChildEntrySection) => (key: React.Key) => {
      return (
        <div className={b('subentry-section')} key={key}>
          {childrenSection.map((subentry, index) => (
            <ChildEntry.Component
              key={index}
              className={b('subentry')}
              childEntry={subentry}
              onSelect={handleChildEntrySelect}
            />
          ))}
        </div>
      );
    };

  const handleBodyClick = useCallback(function (event: MouseEvent) {
    const clickedNode = event.target as HTMLElement;

    if (!childrenRef.current?.contains(clickedNode)) {
      childrenModeUnit.setState({ kind: 'hidden' });
      document.body.removeEventListener('click', handleBodyClick);
    }
  }, []);

  const labelRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => document.body.removeEventListener('click', handleBodyClick);
  }, []);

  const handleLabelClick = useCallback(() => {
    if (childrenModeUnit.getState().kind === 'visible') {
      childrenModeUnit.setState({ kind: 'hidden' });
      document.body.removeEventListener('click', handleBodyClick);
    } else {
      childrenModeUnit.setState({ kind: 'visible' });
      // NOTE React will execute event listener within this event if we add it without waiting for callstack clearing
      setTimeout(() => {
        document.body.addEventListener('click', handleBodyClick);
      }, 0);
    }
  }, []);

  const handleChildrenTransitionEnd = useCallback(() => {
    if (childrenModeUnit.getState().kind === 'hidden') {
      childrenModeUnit.setState({ kind: 'not-rendered' });
    }
  }, []);

  const renderChildren = () => {
    return createPortal(
      <div
        className={b('children-popup', { mode: childrenMode.kind })}
        ref={childrenRef}
        style={childrenPopupStyles}
        onTransitionEnd={handleChildrenTransitionEnd}
      >
        {R.intersperse(
          (key: React.Key) => (
            <div key={key} className={b('section-separator')} />
          ),
          childrenSections.map(makeChildrenSectionRenderer),
        ).map((renderer, index) => renderer(index))}
      </div>,
      document.body,
    );
  };

  const menuIsOpenUnit = childrenModeUnit.useBridgeUnitMemo<boolean>(
    state => state.kind === 'visible',
    state => (state ? { kind: 'visible' } : { kind: 'hidden' }),
  );

  useLayoutEffect(() => {
    if (childrenMode.kind === 'not-rendered') {
      return;
    }

    updateChildrenPopupStyles();

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateChildrenPopupStyles,
    });

    return () => {
      unsubscribe();

      setChildrenPopupStyles(undefined);
    };
  }, [childrenMode.kind, updateChildrenPopupStyles]);

  return (
    <div className={classnames(b(), className)} ref={labelRef}>
      <div className={b('label')} onClick={handleLabelClick}>
        <Label isActive={isActive} menuIsOpenUnit={menuIsOpenUnit} />
      </div>
      {childrenMode.kind !== 'not-rendered' && renderChildren()}
    </div>
  );
}

export const Component = React.memo(RootParentEntry) as typeof RootParentEntry;
