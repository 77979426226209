import * as M from 'types/serverModels';

export const hasLicense = (user: Pick<M.User, '_licenses'>) =>
  Array.isArray(user._licenses) && user._licenses.length > 0;

export const hasChosenLicense = (
  user: Pick<M.User, '_licenses'>,
  license: M.License | null,
) => {
  return (
    license !== null &&
    hasLicense(user) &&
    user._licenses!.some(
      userLicense => license.product.uuid === userLicense.product_uuid,
    )
  );
};
