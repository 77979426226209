import React, { useEffect } from 'react';
import { Route } from 'react-router';

import { ProjectAssignmentRouteTree } from 'pages/routeTree';
import { API } from 'services';
import * as M from 'types/serverModels';

import * as About from './About';
import * as Answers from './Answers';
import * as Assignees from './Assignees';
import * as Results from './Results';
import * as Task from './Task';
import * as features from './features';

type Props = {
  projectAssignmentRouteTree: ProjectAssignmentRouteTree;
  projectAssignment: M.ProjectAssignment;
  users: M.ProjectAssignmentUser[];
};

function Content({
  projectAssignmentRouteTree,
  project,
}: React.ComponentProps<features.Page.Props['Content']>) {
  return (
    <>
      <Route path={About.path}>
        <About.Component
          projectAssignmentRouteTree={projectAssignmentRouteTree}
          project={project}
        />
      </Route>
      <Route path={Assignees.path}>
        <Assignees.Component
          projectAssignmentRouteTree={projectAssignmentRouteTree}
          project={project}
        />
      </Route>
      <Route path={Task.path}>
        <Task.Component
          projectAssignmentRouteTree={projectAssignmentRouteTree}
          project={project}
        />
      </Route>
      <Route path={Answers.path}>
        <Answers.Component
          projectAssignmentRouteTree={projectAssignmentRouteTree}
          project={project}
        />
      </Route>
      <Route path={Results.path}>
        <Results.Component
          projectAssignmentRouteTree={projectAssignmentRouteTree}
          project={project}
        />
      </Route>
    </>
  );
}

function ProjectAssignment({
  projectAssignmentRouteTree,
  projectAssignment,
  users,
}: Props) {
  const callStateUnit = API.services.project.get.useCallStateUnit();
  const callState = callStateUnit.useState();
  const call = API.services.project.get.useCall(callStateUnit);

  useEffect(() => {
    call({ uuidOrCode: projectAssignment.project });
  }, [call, projectAssignment.project]);

  return API.renderCallState(callState, {
    successful: state => (
      <features.Page.Component
        Content={Content}
        project={state.data}
        projectAssignmentRouteTree={projectAssignmentRouteTree}
        projectAssignment={projectAssignment}
        users={users}
      />
    ),
  });
}

export const Component = React.memo(ProjectAssignment);
