import { makeSharedEntryReference } from 'services/I18n';

export const doneButtonLabelReference = makeSharedEntryReference(
  data => data.doneButtonLabel,
);

export const cancelButtonLabelReference = makeSharedEntryReference(
  data => data.cancelButtonLabel,
);

export const projectBookChangeEventLabelReferences = {
  passport_change: makeSharedEntryReference(
    data => data.projectBook.change.event.passport_change.label,
  ),
  fields_change: makeSharedEntryReference(
    data => data.projectBook.change.event.fields_change.label,
  ),
  fields_units_change: makeSharedEntryReference(
    data => data.projectBook.change.event.fields_units_change.label,
  ),
  status_change: makeSharedEntryReference(
    data => data.projectBook.change.event.status_change.label,
  ),
  stages_task_states_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_task_states_change.label,
  ),
  date_change: makeSharedEntryReference(
    data => data.projectBook.change.event.date_change.label,
  ),
  assessment_change: makeSharedEntryReference(
    data => data.projectBook.change.event.assessment_change.label,
  ),
  stages_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_change.label,
  ),
  stages_status_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_status_change.label,
  ),
  stages_date_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_date_change.label,
  ),
  stages_assessment_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_assessment_change.label,
  ),
  stages_board_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_board_change.label,
  ),
  stages_units_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_units_change.label,
  ),
  stages_materials_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_materials_change.label,
  ),
  stages_boards_comments_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_boards_comments_change.label,
  ),
  stages_materials_comments_change: makeSharedEntryReference(
    data =>
      data.projectBook.change.event.stages_materials_comments_change.label,
  ),
  skills_change: makeSharedEntryReference(
    data => data.projectBook.change.event.skills_change.label,
  ),
  reflexion_comments_change: makeSharedEntryReference(
    data => data.projectBook.change.event.reflexion_comments_change.label,
  ),
  stages_tasks_change: makeSharedEntryReference(
    data => data.projectBook.change.event.stages_tasks_change.label,
  ),
  members_supervisors_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_supervisors_change.label,
  ),
  members_participants_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_participants_change.label,
  ),
  members_watchers_change: makeSharedEntryReference(
    data => data.projectBook.change.event.members_watchers_change.label,
  ),
};
