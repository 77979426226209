import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as ExtraIcon } from 'shared/images/extra.svg';
import {
  getScrollableAncestors,
  getFixedPositionStyle,
  stickFixedPositionElement,
} from 'utils/DOM';
import { block } from 'utils/classname';

import type * as Button from '../Button';
import './style.scss';

type Variant = 'contained' | 'outlined';
type Size = 's' | 'm' | 'l';

const b = block('extra-menu');

export type Props = {
  Button?: React.VFC<Button.ProvidedProps>;
  className?: string;
  variant?: Variant;
  size?: Size;
  children: React.ReactNode;
};

function ExtraMenu({
  Button,
  className,
  variant = 'outlined',
  size = 'm',
  children,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [optionsStyle, setOptionsStyle] = useState<React.CSSProperties>();

  const ref = useRef<HTMLButtonElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);

  const isPositioned = isExpanded && optionsStyle !== undefined;

  const updateOptionsStyle = useCallback(() => {
    if (ref.current === null) {
      return null;
    }

    setOptionsStyle(
      getFixedPositionStyle({
        anchorRect: ref.current.getBoundingClientRect(),
        margin: 5,
        defaultMaxHeight: 300,
      }),
    );
  }, []);

  const handleClick = useCallback(() => {
    setIsExpanded(prev => !prev);
  }, []);

  const handleAncestorScroll = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const handleListClick: React.MouseEventHandler = useCallback(event => {
    event.stopPropagation();
  }, []);

  const handleListKeyDown: React.KeyboardEventHandler = useCallback(event => {
    switch (event.key) {
      case 'Escape': {
        ref.current?.focus();

        setIsExpanded(false);

        break;
      }
    }
  }, []);

  const handleDocumentBodyClick = useCallback((e: MouseEvent) => {
    if (!(e.target instanceof Node)) {
      return;
    }

    if (
      ref.current?.contains(e.target) ||
      optionsRef.current?.contains(e.target)
    ) {
      return;
    }

    setIsExpanded(false);
  }, []);

  useLayoutEffect(() => {
    if (!isExpanded || ref.current === null) {
      return;
    }

    updateOptionsStyle();

    const scrollableAncestors = getScrollableAncestors(ref.current);

    scrollableAncestors.forEach(x => {
      x.addEventListener('scroll', handleAncestorScroll);
    });

    document.body.addEventListener('click', handleDocumentBodyClick);

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateOptionsStyle,
    });

    return () => {
      scrollableAncestors.forEach(x => {
        x.removeEventListener('scroll', handleAncestorScroll);
      });

      document.body.removeEventListener('click', handleDocumentBodyClick);

      unsubscribe();

      setOptionsStyle(undefined);
    };
  }, [
    isExpanded,
    updateOptionsStyle,
    handleAncestorScroll,
    handleDocumentBodyClick,
  ]);

  useEffect(() => {
    if (isPositioned) {
      optionsRef.current?.focus();
    }
  }, [isPositioned]);

  return (
    <>
      {Button ? (
        <Button
          forwardedRef={ref}
          counter={React.Children.toArray(children).length}
          expanded={isExpanded}
          onClick={handleClick}
        />
      ) : (
        <button
          className={b({ variant, size, expanded: isExpanded }, [className])}
          ref={ref}
          onClick={handleClick}
        >
          <ExtraIcon className={b('icon')} />
        </button>
      )}
      {isExpanded &&
        createPortal(
          <ul
            className={b('list')}
            ref={optionsRef}
            tabIndex={-1}
            style={optionsStyle}
            onKeyDown={handleListKeyDown}
            onClick={handleListClick}
          >
            {children}
          </ul>,
          document.body,
        )}
    </>
  );
}

export const Component = ExtraMenu;
