import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import { UserRole } from '../types';

export function getUserRole(
  projectBook: M.ProjectBook,
  user: M.User,
): UserRole {
  if (projectBook.supervisors?.includes(user.login)) {
    return 'supervisor';
  }

  if (projectBook.participants?.includes(user.login)) {
    return 'participant';
  }

  if (projectBook.watchers?.includes(user.login)) {
    return 'watcher';
  }

  if (user.role === USER_ROLE.moderator) {
    return 'watcher-moderator';
  }

  throw new Error(
    'not supervisor and not participant and not watcher and not moderator',
  );
}
