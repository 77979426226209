import React, { useMemo } from 'react';

import { Checkbox } from 'components';
import { I18n } from 'services';
import { block } from 'utils/classname';

import { AgeRangeState } from '../types';
import './style.scss';

const b = block('age-range-element');

type Props = {
  ageRangeState: AgeRangeState;
};

function AgeRangeElement({ ageRangeState }: Props) {
  const t = I18n.useGetMultilingProjectTranslation();

  const Label = useMemo(() => {
    return Checkbox.makeLabel({
      useLabel: () => t(ageRangeState.label),
      size: 'text-14',
    });
  }, [ageRangeState.label, t]);

  return (
    <Checkbox.Component
      className={b()}
      formElementState={ageRangeState.value}
      Label={Label}
    />
  );
}

export const Component = React.memo(AgeRangeElement);
