import * as R from 'ramda';

//TODO Improve types
export function deepOmit<T>(names: string[], value: T): T {
  function loop(names: string[], obj: unknown): T {
    return Object.entries(R.omit(names, obj)).reduce(
      (acc, [key, value]) => ({ ...acc, [key]: deepOmit(names, value) }),
      {} as T,
    );
  }
  switch (R.type(value)) {
    case 'Array':
      return (value as unknown[]).map(x => deepOmit(names, x)) as T;
    case 'Object':
      return loop(names, value);
    default:
      return value;
  }
}
