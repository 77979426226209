import { USER_ROLE } from 'shared/constants';
import * as M from 'types/serverModels';

import { ProjectBookUser, ShadowUserRole } from '../types';

export function getShadowUserRole(
  projectBook: M.ProjectBook,
  projectBookUser: ProjectBookUser,
  user: M.User,
): ShadowUserRole {
  if (projectBookUser !== null) {
    return 'participant';
  }

  if (projectBook.supervisors?.includes(user.login)) {
    return 'supervisor';
  }

  if (projectBook.participants?.includes(user.login)) {
    return 'participant';
  }

  if (
    user.role === USER_ROLE.moderator ||
    projectBook.watchers?.includes(user.login)
  ) {
    return projectBook.is_individual ? 'supervisor' : 'participant';
  }

  throw new Error('not supervisor and not participant and not watcher');
}
