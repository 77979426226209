import * as M from 'types/serverModels';

import { makeService } from '../../utils';

type PathInputData = {
  uuid: Exclude<M.ProjectBook['uuid'], undefined>;
};

type BodyInputData = {
  status: Exclude<M.ProjectBook['status'], undefined>;
  user?: M.UserXShort['login'];
};

type InputData = PathInputData & BodyInputData;

type APIOutputData = {
  project_book: M.ProjectBook;
  users?: M.UserXShort[];
  projects?: M.ProjectXShort[];
  autochanges?: M.ProjectBookAutoChange[];
};

type OutputData = {
  projectBook: M.ProjectBook;
  users: M.UserXShortRecord;
  projects: M.ProjectXShorts;
  autoChanges: M.ProjectBookAutoChange[];
};

function extractResponse(data: APIOutputData): OutputData {
  return {
    projectBook: data.project_book,
    users:
      data?.users?.reduce<M.UserXShortRecord>(
        (acc, x) =>
          x.login !== undefined ? { ...acc, [x.login]: x } : { ...acc },
        {},
      ) || {},
    projects:
      data?.projects?.reduce<M.ProjectXShorts>(
        (acc, x) =>
          x.uuid !== undefined ? { ...acc, [x.uuid]: x } : { ...acc },
        {},
      ) || {},
    autoChanges: data.autochanges || [],
  };
}

export const put = makeService<InputData, APIOutputData, OutputData>(
  ({ ticket, uuid }, convertToSearchParams) =>
    `project_book/${uuid}/status/?${convertToSearchParams({ ticket })}`,
  'api-v2-put',
  false,
  extractResponse,
  {
    contentType: 'application/x-www-form-urlencoded',
    extractBodyInput: input => {
      const { ticket, uuid, ...bodyInput } = input;

      return bodyInput;
    },
  },
);
