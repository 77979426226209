import React, { useRef, useMemo, useLayoutEffect } from 'react';
import ReactDOM from 'react-dom';

import { Device } from 'services';
import { makePrimaryUnit } from 'utils/State';
import { block } from 'utils/classname';

import * as Desktop from './Desktop';
import * as Mobile from './Mobile';

export * as Desktop from './Desktop';
export * as Header from './Header';
export * as Layout from './Layout';
export * as Footer from './Footer';
export * as InfoText from './InfoText';

export type { Size } from './types';

const b = block('modal-portal');

export type Props = Desktop.Props & {
  desktopOnly?: boolean;
  mobileOnly?: boolean;
  onClick?(event: MouseEvent | React.MouseEvent): void;
};

export function makeIsOpenUnit(initialState: boolean = false) {
  return makePrimaryUnit<boolean>(initialState);
}

export function useIsOpenUnit(initialState: boolean = false) {
  return useMemo(() => makeIsOpenUnit(initialState), [initialState]);
}

const ChildrenPortal: React.FC<{
  childrenRef: React.RefObject<HTMLDivElement>;
  dependency: unknown[];
}> = ({ childrenRef, dependency, children }) => {
  const portalRef = useRef<HTMLDivElement>();

  if (!portalRef.current) {
    portalRef.current = document.createElement('div');
    portalRef.current.classList.add(b('children-portal'));
  }

  useLayoutEffect(() => {
    const portalElement = portalRef.current;
    const childrenElement = childrenRef.current;

    if (!portalElement || !childrenElement) {
      return;
    }

    childrenElement.appendChild(portalElement);

    return () => {
      childrenElement.removeChild(portalElement);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependency);

  return ReactDOM.createPortal(children, portalRef.current);
};

function Modal(props: React.PropsWithChildren<Props>) {
  const device = Device.shortUnit.useState();

  const childrenRef = useRef<HTMLDivElement>(null);

  const isOpen = props.isOpenUnit.useState();

  if (!isOpen) {
    return null;
  }

  const isMobile =
    (device === 'mobile' || props.mobileOnly) && !props.desktopOnly;

  return (
    <>
      {ReactDOM.createPortal(
        <div className={b()} onClick={props.onClick}>
          {(() => {
            if (isMobile) {
              return (
                <Mobile.Component
                  isOpenUnit={props.isOpenUnit}
                  Header={props.Header}
                  onClose={props.onClose}
                  size={props.size}
                  className={props.className}
                >
                  <div className={b('children')} ref={childrenRef} />
                </Mobile.Component>
              );
            }

            return (
              <Desktop.Component {...props}>
                <div className={b('children')} ref={childrenRef} />
              </Desktop.Component>
            );
          })()}
          <ChildrenPortal childrenRef={childrenRef} dependency={[isMobile]}>
            {props.children}
          </ChildrenPortal>
        </div>,
        document.body,
      )}
    </>
  );
}

export const Component = Modal;
