import { isRequired } from 'features/Questions/i18nSharedReferences';
import { I18n } from 'services';
import * as TS from 'types';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString } from 'utils/validators';

import { VariantSelectionQuestion } from '../../subfeatures';

const makeVariantProperties = (
  variant: M.Variant,
  lang: TS.Language,
  langs: TS.Language[],
): VariantSelectionQuestion.VariantProperties => {
  return {
    id: variant.uuid,
    text: variant.title
      ? I18n.getMultilingTranslation(lang, variant.title, langs)
      : '',
    explanation: variant.description
      ? I18n.getMultilingTranslation(lang, variant.description, langs)
      : '',
    imageURL: variant.image?.icon || null,
  };
};

export const makeElementModels = (
  variant: M.Variant,
  lang: TS.Language,
  langs: TS.Language[],
  initialText?: string,
): VariantSelectionQuestion.SingleChoiceElementModel => {
  return variant.custom
    ? {
        kind: 'own',
        textState: makeFormElementState(initialText || '', [
          nonEmptyString(isRequired),
        ]),
        properties: makeVariantProperties(variant, lang, langs),
      }
    : {
        kind: 'regular',
        properties: makeVariantProperties(variant, lang, langs),
      };
};
