import { useRef } from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.NumberQuestion,
  initialValue: string = '',
) => {
  return makeFormElementState(
    initialValue,
    !question.optional ? [nonEmptyString(questionIsRequired)] : [],
  );
};

export const useState = (question: M.NumberQuestion, initialValue?: string) => {
  return useRef(makeState(question, initialValue)).current;
};
