import React, { useEffect } from 'react';

import { ErrorMessage } from 'components';
import { ProjectWriteContext } from 'features/project/Constructor/FormWithSteps/ProjectWriteContext';
import { API } from 'services';
import { block } from 'utils/classname';
import { useRequiredContext } from 'utils/react/RequiredContext';

import {
  subjectsUnit,
  subjectsFormSectionState,
  subjectsChecksUnit,
} from '../../units/inputState';
import * as Subject from './Subject';
import './style.scss';

const b = block('subjects-section');

type Props = {};

function Subjects({}: Props) {
  const callState = API.references.themes.useCallState();

  const subjects = subjectsUnit.useState();
  const error = subjectsFormSectionState.units.error.useState();

  const { saveProject } = useRequiredContext(ProjectWriteContext);

  useEffect(() => {
    if (callState.kind !== 'successful') {
      return;
    }
    return subjectsChecksUnit.subscribe({
      name: 'subjects-changed',
      callback: saveProject,
    });
  }, [callState.kind, saveProject]);

  return (
    <div className={b()}>
      <div className={b('checkboxes', { invalid: error !== null })}>
        {subjects.map(x => (
          <Subject.Component key={x.id} subjectState={x} />
        ))}
      </div>
      <ErrorMessage.Component messageReference={error} rows={1} />
    </div>
  );
}

export const Component = React.memo(Subjects);
