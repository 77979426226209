import React, {
  useRef,
  useState,
  useCallback,
  useLayoutEffect,
  useEffect,
} from 'react';

import { Page } from 'services';
import crossIconSrc from 'shared/images/cross.svg';
import { emulateClick, stickFixedPositionElement } from 'utils/DOM';
import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import { defaults } from './constants';
import './style.scss';
import { Size } from './types';

const b = block('modal');
const shadow = block('modal-shadow');

export type Position =
  | PositionOnCenter
  | PositionWithTopRightAttachedToNode
  | PositionWithTopRightAttachedToNodeWithoutArrow
  | PositionWithTopLeftAttachedToNode;

type PositionOnCenter = {
  kind: 'on-center';
};

type PositionWithTopRightAttachedToNode = {
  kind: 'with-top-right-attached-to-node';
  nodeRef: React.RefObject<HTMLElement>;
};

type PositionWithTopRightAttachedToNodeWithoutArrow = {
  kind: 'with-top-right-attached-to-node-without-arrow';
  nodeRef: React.RefObject<HTMLElement>;
};

type PositionWithTopLeftAttachedToNode = {
  kind: 'with-left-top-attached-to-node';
  nodeRef: React.RefObject<HTMLElement>;
};

type ClosingMethod =
  | { kind: 'switch'; withoutShadow?: boolean }
  | { kind: 'fold'; nodeRef: React.RefObject<HTMLElement> };

export type Props = {
  isOpenUnit: PrimaryStateUnit<boolean>;
  size: Size;
  Header?: React.FC;
  className?: string;
  shadowClassName?: string;
  position?: Position;
  closingMethod?: ClosingMethod;
  alignCenter?: boolean;
  onClose?(): boolean | void;
  shadowOptions?: {
    backgroundDarkness?: '80' | '100';
  };
};

function getAttachedStyle(
  position: Position,
  modal: HTMLDivElement | null,
): React.CSSProperties | undefined {
  switch (position.kind) {
    case 'on-center': {
      if (!modal) return;

      const rect = modal.getBoundingClientRect();

      return {
        top: `calc(50% - ${rect.height / 2}px)`,
        left: `calc(50% - ${rect.width / 2}px)`,
      };
    }
    case 'with-top-right-attached-to-node':
    case 'with-top-right-attached-to-node-without-arrow':
    case 'with-left-top-attached-to-node': {
      if (position.nodeRef.current === null) {
        console.warn('provided an empty ref, can not attach to it');

        return undefined;
      }

      const nodeRect = position.nodeRef.current.getBoundingClientRect();

      const style = (() => {
        switch (position.kind) {
          case 'with-top-right-attached-to-node': {
            const topMargin = 30;

            const top = nodeRect.bottom + topMargin;

            return {
              top,
              left: nodeRect.right,
              minWidth: 'min-content',
              maxWidth: nodeRect.right + 60,
              maxHeight: document.documentElement.clientHeight - top,
            };
          }
          case 'with-top-right-attached-to-node-without-arrow': {
            const topMargin = 20;

            const top = nodeRect.bottom + topMargin;

            return {
              top,
              left: nodeRect.right,
              minWidth: 'min-content',
              maxWidth: nodeRect.right,
              maxHeight: document.documentElement.clientHeight - top,
            };
          }
          case 'with-left-top-attached-to-node': {
            const leftMargin = 30;
            const topMargin = -30;

            const top = nodeRect.top + topMargin;
            const left = nodeRect.right + leftMargin;

            return {
              top,
              left,
              minWidth: 'min-content',
              maxWidth: document.documentElement.clientWidth - left,
              maxHeight: document.documentElement.clientHeight - top,
            };
          }
        }
      })();

      return style;
    }
    default: {
      return {};
    }
  }
}

function Modal({
  isOpenUnit,
  children,
  size,
  onClose,
  Header,
  className,
  shadowClassName,
  position = defaults.position,
  closingMethod = defaults.closingMethod,
  alignCenter = false,
  shadowOptions = defaults.shadowOptions,
}: React.PropsWithChildren<Props>) {
  const isOpen = isOpenUnit.useState();

  const [attachedStyle, setAttachedStyle] = useState<
    React.CSSProperties | undefined
  >();

  const modalRef = useRef<HTMLDivElement>(null);

  const withShadow =
    closingMethod.kind === 'switch' && !closingMethod.withoutShadow;
  const shouldAttachPositionStyle =
    position.kind === 'on-center' ||
    position.kind === 'with-top-right-attached-to-node' ||
    position.kind === 'with-top-right-attached-to-node-without-arrow' ||
    position.kind === 'with-left-top-attached-to-node';
  const shouldSetPageScroll = withShadow;
  const isPositioned =
    isOpen && (shouldAttachPositionStyle ? attachedStyle !== undefined : true);

  Page.useSetScroll(shouldSetPageScroll && isOpen);

  const updateAttachedStyle = useCallback(() => {
    setAttachedStyle(getAttachedStyle(position, modalRef.current));
  }, [position]);

  const handleShadowClick: React.MouseEventHandler = useCallback(event => {
    event.preventDefault();
  }, []);

  const handleCloseIconClick = useCallback(() => {
    if (onClose?.() !== false) {
      isOpenUnit.setState(false);
    }
  }, [isOpenUnit, onClose]);

  const handleCrossIconKeyDown: React.KeyboardEventHandler = useCallback(
    event => {
      emulateClick(event);
    },
    [],
  );

  const handleDocumentClick = useCallback(
    event => {
      switch (closingMethod.kind) {
        case 'fold': {
          if (!(event.target instanceof Node)) {
            return;
          }

          if (
            modalRef.current?.contains(event.target) ||
            closingMethod.nodeRef.current?.contains(event.target)
          ) {
            return;
          }

          isOpenUnit.setState(false);
        }
      }
    },
    [closingMethod, isOpenUnit],
  );

  const handleDocumentKeyDown = useCallback(
    event => {
      switch (closingMethod.kind) {
        case 'fold': {
          const shouldClose = !event.repeat && event.code === 'Escape';

          if (shouldClose) {
            isOpenUnit.setState(false);
          }
        }
      }
    },
    [closingMethod, isOpenUnit],
  );

  useLayoutEffect(() => {
    if (!isOpen) {
      return;
    }

    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleDocumentKeyDown);

    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, [isOpen, handleDocumentClick, handleDocumentKeyDown]);

  useLayoutEffect(() => {
    if (!shouldAttachPositionStyle || !isOpen) {
      return;
    }

    updateAttachedStyle();

    const unsubscribe = stickFixedPositionElement({
      updatePosition: updateAttachedStyle,
    });

    return () => {
      unsubscribe();

      setAttachedStyle(undefined);
    };
  }, [shouldAttachPositionStyle, isOpen, updateAttachedStyle]);

  useEffect(() => {
    if (isPositioned) {
      modalRef.current?.focus();
    }
  }, [isPositioned]);

  return (
    <>
      {withShadow && (
        <div
          className={shadow(
            {
              'background-darkness': shadowOptions?.backgroundDarkness,
            },
            [shadowClassName],
          )}
          onClick={handleShadowClick}
        />
      )}
      <div
        className={b(
          {
            open: isOpen,
            size,
            position: position.kind,
            'align-center': alignCenter,
          },
          [className],
        )}
        style={attachedStyle}
        tabIndex={-1}
        ref={modalRef}
      >
        {(position.kind === 'with-top-right-attached-to-node' ||
          position.kind === 'with-left-top-attached-to-node') && (
          <div className={b('arrow')} />
        )}
        {closingMethod.kind === 'switch' && (
          <img
            src={crossIconSrc}
            className={b('close-icon')}
            alt="close-icon"
            tabIndex={0}
            onClick={handleCloseIconClick}
            onKeyDown={handleCrossIconKeyDown}
          />
        )}
        {Header && (
          <div className={b('header')}>
            <Header />
          </div>
        )}
        <div className={b('content')}>{children}</div>
      </div>
    </>
  );
}

export const Component = React.memo(Modal) as typeof Modal;
