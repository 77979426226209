import React, { forwardRef, useCallback } from 'react';

import { Preloader } from 'components';
import { API } from 'services';
import { AbstractStateUnit } from 'utils/State';
import { block, classnames } from 'utils/classname';

import './style.scss';

const b = block('icon-container');

export type Size = 'xl' | 'l' | 'm' | 's' | 'm-half' | 's-half' | 'xs';
export type Variant = 'contained' | 'outlined';
export type BackgroundDarknessLevel = '0' | 1 | 2 | 3;

export type Props = {
  size: Size;
  className?: string;
  variant?: Variant;
  backgroundDarknessLevel?: BackgroundDarknessLevel;
  callStateUnit?: AbstractStateUnit<API.CallState<unknown>>;
  disabled?: boolean;
} & Pick<
  JSX.IntrinsicElements['div'],
  | 'title'
  | 'tabIndex'
  | 'onClick'
  | 'onMouseEnter'
  | 'onMouseLeave'
  | 'onMouseMove'
>;

const IconContainer = forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<Props>
>(function (
  {
    size,
    children,
    className,
    variant = 'contained',
    backgroundDarknessLevel = 1,
    callStateUnit,
    disabled,
    onClick,
    ...restProps
  }: React.PropsWithChildren<Props>,
  ref,
) {
  const callState = callStateUnit?.useState();

  const sumDisabled = callState?.kind === 'pending' || disabled;

  const handleKeyDown: React.KeyboardEventHandler = useCallback(event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  }, []);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
    event => {
      if (sumDisabled) {
        return;
      }

      onClick?.(event);
    },
    [sumDisabled, onClick],
  );

  return (
    <div
      className={classnames(
        b({
          size,
          variant,
          'background-darkness-level': backgroundDarknessLevel,
          disabled: sumDisabled,
        }),
        className,
      )}
      ref={ref}
      onKeyDown={handleKeyDown}
      {...restProps}
      tabIndex={!sumDisabled ? restProps.tabIndex : undefined}
      onClick={handleClick}
    >
      {children}
      {callState?.kind === 'pending' && (
        <Preloader.Component className={b('preloader')} size="xs" />
      )}
    </div>
  );
});

export const Component = React.memo(IconContainer) as typeof IconContainer;
