import React, { useMemo } from 'react';
import { Route } from 'react-router';
import { validate } from 'uuid';

import { ConclusionsRouteTree } from 'pages/routeTree';

import * as Conclusion from './Conclusion';
import * as Conclusions from './Conclusions';
import * as Cover from './Cover';
import * as Members from './Members';
import * as Progress from './Progress';
import * as Projects from './Projects';
import * as Quiz from './Quiz';
import * as Track from './Track';
import * as features from './features';

type Props = Omit<features.Page.Props, 'Content'>;

function Content({ course, coursePageRouteTree }: Props) {
  const conclusionsRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...coursePageRouteTree,
      tree: coursePageRouteTree.tree.conclusions,
    };
  }, [coursePageRouteTree]);

  return (
    <>
      <Route path={Cover.path} exact>
        <Cover.Component course={course} />
      </Route>
      {!course.options?.menu?.hide_track && (
        <Route path={Track.path}>
          <Track.Component
            coursePageRouteTree={coursePageRouteTree}
            course={course}
          />
        </Route>
      )}
      <Route path={Progress.path}>
        <Progress.Component
          coursePageRouteTree={coursePageRouteTree}
          course={course}
        />
      </Route>
      {!course.options?.menu?.hide_projects && (
        <Route path={Projects.path} exact>
          <Projects.Component />
        </Route>
      )}
      {/* <Route path={Results.path} exact>
        <Results.Component course={course} />
      </Route>
      <Route path={Blog.path} exact>
        <Blog.Component course={course.uuid} />
      </Route>
      <Route path={BlogMessage.path} exact>
        <BlogMessage.Component course={course.uuid} />
      </Route> */}
      {typeof course.conclusions === 'string' &&
        validate(course.conclusions) && (
          <Route path={Conclusions.path} exact>
            <Conclusions.Component
              conclusionsUUID={course.conclusions}
              conclusionsRouteTree={conclusionsRouteTree}
              course={course}
            />
          </Route>
        )}
      <Route path={Members.path} exact>
        <Members.Component course={course.uuid} />
      </Route>
    </>
  );
}

function Course({ course, coursePageRouteTree }: Props) {
  const conclusionsRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...coursePageRouteTree,
      tree: coursePageRouteTree.tree.conclusions,
    };
  }, [coursePageRouteTree]);

  const quizRouteTree: ConclusionsRouteTree = useMemo(() => {
    return {
      ...coursePageRouteTree,
      tree: coursePageRouteTree.tree.quiz,
    };
  }, [coursePageRouteTree]);

  const quizParams = quizRouteTree.tree.form.QUIZ_UUID.useRouteParams();

  if (conclusionsRouteTree.tree.form.useRouteParams()) {
    const isIncludesConclusions =
      typeof course.conclusions === 'string' && validate(course.conclusions);

    if (!isIncludesConclusions) {
      return null;
    }

    return (
      <Route path={Conclusion.path} exact>
        <Conclusion.Component
          conclusionsRouteTree={conclusionsRouteTree}
          title={course.name}
          uuid={course.uuid}
          kind="conclusions"
          objectUUID={course.conclusions as string}
          status={course.status}
          authors={course.author}
          supervisors={course.supervisor}
          courseCharge={course.charge}
        />
      </Route>
    );
  }

  if (quizParams) {
    return (
      <Route path={Quiz.path} exact>
        <Quiz.Component
          conclusionsRouteTree={quizRouteTree}
          uuid={course.uuid}
          kind="quiz"
          objectUUID={quizParams.quiz_uuid}
        />
      </Route>
    );
  }

  return (
    <features.Page.Component
      Content={Content}
      course={course}
      coursePageRouteTree={coursePageRouteTree}
    />
  );
}

export const Component = React.memo(Course) as typeof Course;
