import { useMemo } from 'react';

import { I18n } from 'services';
import { projectLangsUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { FormElementState } from 'utils/FormState';

import { VariantSelectionQuestion } from '../../subfeatures';
import { makeElementModels } from './makeElementModels';

export function useModel(
  data: M.SingleChoiceQuestion | M.SingleChoiceQuizQuestion,
  state: FormElementState<VariantSelectionQuestion.SingleChoiceElementModel | null>,
  initialText?: Record<string, string>,
): VariantSelectionQuestion.Model {
  const lang = I18n.activeLangStateUnit.useState();
  const projectLangs = projectLangsUnit.useState();
  const isDropdown = 'control' in data && !!data.control?.dropdown;

  return useMemo(
    () => ({
      kind: 'single',
      display: isDropdown ? 'select' : 'radio',
      valueState: state,
      elementModels: data.variants.map(x =>
        makeElementModels(x, lang, projectLangs, initialText?.[x.uuid]),
      ),
    }),
    [data.variants, initialText, isDropdown, lang, projectLangs, state],
  );
}
