import { useCallback, useMemo } from 'react';

import { VariantSelectionQuestion } from 'features/question';
import { I18n } from 'services';
import * as M from 'types/serverModels';

import { StateItem } from './types';

export function useModel(
  data: M.MultiChoiceQuestion | M.MultiChoiceQuizQuestion,
  state: StateItem | null,
): VariantSelectionQuestion.Model | null {
  const t = I18n.useGetMultilingProjectTranslation();

  const makeVariantProperties = useCallback(
    (variant: M.Variant): VariantSelectionQuestion.VariantProperties => ({
      id: variant.uuid,
      text: variant.title ? t(variant.title) : '',
      explanation: variant.description ? t(variant.description) : '',
      imageURL: variant.image?.icon || null,
    }),
    [t],
  );

  return useMemo(() => {
    if (!state) return null;

    return {
      kind: 'multiple',
      elementModels: data.variants.map((x): any => {
        const custom = 'custom' in x && x.custom;
        return custom
          ? {
              kind: 'own',
              checkedState: state[x.uuid].valueState,
              properties: makeVariantProperties(x),
              textState: state[x.uuid].textState,
            }
          : {
              kind: 'regular',
              checkedState: state[x.uuid].valueState,
              properties: makeVariantProperties(x),
            };
      }),
    };
  }, [data.variants, makeVariantProperties, state]);
}
