import * as M from 'types/serverModels';

import { makeService } from '../utils';

type InputData = {
  uuidOrCode: string;
};

type APIOutputData = {
  project: M.Project;
};

export const get = makeService<InputData, APIOutputData, M.Project>(
  ({ uuidOrCode }) => `project/${uuidOrCode}`,
  'api-v2-get',
  false,
  ({ project }) => project,
);
