import { makeSharedEntryReference } from 'services/I18n/makeEntryReference';

export const isRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredField,
);

export const questionIsRequired = makeSharedEntryReference(
  data => data.validationErrors.requiredQuestion,
);

export const imageIsRequired = makeSharedEntryReference(
  data => data.validationErrors.imageRequired,
);

export const payWallProjectConclusionTextReference = makeSharedEntryReference(
  data => data.payWallTexts.conclusionsProject,
);

export const payWallCourseConclusionTextReference = makeSharedEntryReference(
  data => data.payWallTexts.joinCourseByLicense,
);
