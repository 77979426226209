import React, { useEffect, useRef } from 'react';

import { PrimaryStateUnit } from 'utils/State';
import { block } from 'utils/classname';

import './style.scss';

const b = block('widget-container');

type Props = {
  Title?: React.FC;
  Content: React.FC;
  Header?: React.FC;
  ControlsBar: React.FC;
  modeUnit?: PrimaryStateUnit<'edit' | 'preview'>;
};

function WidgetContainer({
  Title,
  Content,
  Header,
  ControlsBar,
  modeUnit,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return modeUnit?.subscribe({
      name: 'scroll-into-view',
      callback: () => {
        ref.current?.scrollIntoView();
      },
    });
  }, [modeUnit]);

  return (
    <div className={b()} ref={ref}>
      <div className={b('header')}>
        <div className={b('header-left-part')}>
          {Title && <Title />}
          {Header && <Header />}
        </div>
        <div className={b('header-right-part')}>
          <div className={b('controls-bar')}>
            <ControlsBar />
          </div>
        </div>
      </div>
      <div className={b('content')}>
        <Content />
      </div>
    </div>
  );
}

export const Component = React.memo(WidgetContainer);
