import React from 'react';

import { ConstructorConfigContext } from 'features/project/Constructor/config/configContext';
import i18nData from 'features/project/Constructor/i18n.json';
import { ConstructorFormSection } from 'features/project/Constructor/subfeatures';
import { getProjectLanguage } from 'features/project/Constructor/utils';
import { API, I18n } from 'services';
import { USER_ROLE } from 'shared/constants';
import { userStateUnit } from 'shared/stateUnits';
import { ImageInfoUtility } from 'utils/business';
import { useRequiredContext } from 'utils/react/RequiredContext';

import { Step } from '../../types';
import * as Section from './Section';
import * as sections from './sections';
import { AgeRangeState } from './sections/AgeRange/types';
import { SubjectState } from './sections/Subjects/types';
import { errorsUnit } from './units/errors';
import { hasSomeInputUnit } from './units/hasSomeInput';
import {
  annotationState,
  projectDescriptionState,
  projectImageState,
  ageRangesUnit,
  subjectsUnit,
  keywordsState,
  subjectsFormSectionState,
  ageRangesFormSectionState,
  classificationsUnit,
  weightState,
  perkState,
  selectedSubjectsUnit,
  selectedAgeRangesUnit,
} from './units/inputState';
import { progressInvariantUnitsUnit } from './units/progressInvariants';
import { visitedUnit } from './units/someFieldIsVisited';

type Props = {};

function ProjectAnnouncement({}: Props) {
  const text = I18n.useText(i18nData).steps.projectAnnouncement;
  const { mode } = useRequiredContext(ConstructorConfigContext);
  const userState = userStateUnit.useState();

  return (
    <ConstructorFormSection.Component>
      <Section.Component
        Content={sections.ProjectImage.Component}
        heading={text.sections.projectImage.header}
        tooltip={text.sections.projectImage.tooltip}
      />
      <Section.Component
        Content={sections.Annotation.Component}
        heading={text.sections.annotation.header}
        tooltip={text.sections.annotation.tooltip}
      />
      <Section.Component
        Content={sections.ProjectDescription.Component}
        heading={text.sections.projectDescription.header}
        tooltip={text.sections.projectDescription.tooltip}
      />
      <Section.Component
        Content={sections.Keywords.Component}
        heading={text.sections.keywords.header}
        tooltip={text.sections.keywords.tooltip}
      />
      {mode === 'full' && (
        <>
          <Section.Component
            Content={sections.AgeRange.Component}
            heading={text.sections.ageRange.header}
            tooltip={text.sections.ageRange.tooltip}
          />
          <Section.Component
            Content={sections.Subjects.Component}
            heading={text.sections.subjects.header}
            tooltip={text.sections.subjects.tooltip}
          />
          {userState.kind === 'loaded' &&
            userState.user.role === USER_ROLE.moderator && (
              <>
                <Section.Component
                  Content={sections.Classifications.Component}
                  heading={text.sections.classifications.header}
                  tooltip={text.sections.classifications.tooltip}
                />
                <Section.Component
                  Content={sections.Weight.Component}
                  heading={text.sections.weight.header}
                  tooltip={text.sections.weight.tooltip}
                />
                <Section.Component
                  Content={sections.Perk.Component}
                  heading={text.sections.perk.header}
                  tooltip={text.sections.perk.tooltip}
                />
              </>
            )}
        </>
      )}
    </ConstructorFormSection.Component>
  );
}

const Component = React.memo(ProjectAnnouncement);

export const step: Step = {
  key: 'projectAnnouncement',
  Form: Component,
  errorsUnit,
  visitedUnit,
  progressInvariantUnitsUnit,
  hasSomeInputUnit,
  getProjectData: ({ serverProject }) => {
    const lang = getProjectLanguage();
    const weight = weightState.getValue() || 0;
    const perk = perkState.getValue();

    return {
      about: I18n.makeMultilangFormState(
        annotationState,
        serverProject?.about,
      ).getMergedMultilingString(lang),

      description: I18n.makeMultilangFormState(
        projectDescriptionState,
        serverProject?.description,
      ).getMergedMultilingString(lang),
      ...(() => {
        const info = projectImageState.units.value.getState();
        return info
          ? {
              thumb: {
                large: info.thumb[0].url,
                ...(info.uuid ? { uuid: info.uuid } : {}),
              },
            }
          : { thumb: null };
      })(),
      keywords: keywordsState.units.value
        .getState()
        .split(',')
        .map(x => x.trim()),
      targetAges: selectedAgeRangesUnit.getState(),
      subjects: selectedSubjectsUnit.getState(),
      classifications: classificationsUnit.getState(),
      weight,
      perks: perk ? [perk] : serverProject?.perks,
    };
  },
  fillFromExistingProject: ({ project, language }) => {
    const t = I18n.makeGetMultilingTranslation(language);
    if (project.description) {
      projectDescriptionState.units.value.setState(t(project.description));
    }

    if (project.thumb) {
      projectImageState.units.value.setState(
        ImageInfoUtility.makeIcon(project.thumb.large, project.thumb.uuid),
      );
    }

    if (project.about) {
      annotationState.units.value.setState(t(project.about));
    }

    if (project.keywords) {
      keywordsState.units.value.setState(project.keywords.join(', '));
    }

    if (project.targetAges) {
      API.references.ages.callRefService();

      const updateRangesState = (ageRanges: AgeRangeState[]) => {
        ageRanges.forEach(ageRangeState => {
          if (project.targetAges?.includes(ageRangeState.id)) {
            ageRangeState.value.units.value.setState(true);
          }
        });
      };

      const currentRangesState = ageRangesUnit.getState();
      if (currentRangesState.length > 0) {
        updateRangesState(currentRangesState);
      } else {
        const unsubscribe = ageRangesUnit.subscribe({
          name: 'synchronizer-with-project-data',
          callback: ageRanges => {
            if (ageRanges.length > 0) {
              updateRangesState(ageRanges);
            }
            unsubscribe();
          },
        });
      }
    }

    if (project.subjects) {
      API.references.themes.callRefService();

      const updateSubjectsState = (subjects: SubjectState[]) => {
        subjects.forEach(subject => {
          if (project.subjects?.includes(subject.id)) {
            subject.value.units.value.setState(true);
          }
        });
      };

      const currentSubjectsState = subjectsUnit.getState();
      if (currentSubjectsState.length > 0) {
        updateSubjectsState(currentSubjectsState);
      } else {
        const unsubscribe = subjectsUnit.subscribe({
          name: 'synchronizer-with-project-data',
          callback: subjects => {
            if (subjects.length > 0) {
              updateSubjectsState(subjects);
            }
            unsubscribe();
          },
        });
      }
    }

    if (Array.isArray(project.classifications)) {
      classificationsUnit.setState(project.classifications);
    }

    if (typeof project.weight === 'number') {
      weightState.units.value.setState(project.weight);
    }

    if (typeof project.perks?.[0]?.type === 'number') {
      perkState.units.value.setState(project.perks[0]);
    }
  },
  resetState: () => {
    annotationState.formNode.reset();
    projectImageState.formNode.reset();
    visitedUnit.resetState();
    keywordsState.formNode.reset();
    subjectsFormSectionState.formNode.reset();
    ageRangesFormSectionState.formNode.reset();
    weightState.formNode.reset();
    perkState.formNode.reset();

    ageRangesUnit.getState().forEach(x => {
      x.value.formNode.reset();
    });

    subjectsUnit.getState().forEach(x => {
      x.value.formNode.reset();
    });

    classificationsUnit.resetState();
  },
  resetStateAfterDelay: () => {
    projectDescriptionState.formNode.reset();
  },
};
