import { useRef } from 'react';

import { I18n } from 'services';
import { projectLangsUnit } from 'shared/stateUnits';
import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonEmptyString, nonNull } from 'utils/validators';

import { isRequired, questionIsRequired } from '../i18nSharedReferences';
import { VariantSelectionQuestion } from '../subfeatures';

const makeVariantProperties = (
  variant: M.Variant,
): VariantSelectionQuestion.VariantProperties => {
  const lang = I18n.activeLangStateUnit.getState();
  const langs = projectLangsUnit.getState();

  return {
    id: variant.uuid,
    text: variant.title
      ? I18n.getMultilingTranslation(lang, variant.title, langs)
      : '',
    explanation: variant.description
      ? I18n.getMultilingTranslation(lang, variant.description, langs)
      : '',
    imageURL: variant.image?.icon || null,
  };
};

export const makeElementModels = (
  variant: M.Variant,
  initialText?: string,
): VariantSelectionQuestion.SingleChoiceElementModel => {
  return variant.custom
    ? {
        kind: 'own',
        textState: makeFormElementState(initialText || '', [
          nonEmptyString(isRequired),
        ]),
        properties: makeVariantProperties(variant),
      }
    : {
        kind: 'regular',
        properties: makeVariantProperties(variant),
      };
};

export const makeState = (
  question: M.SingleChoiceQuestion,
  initialValue: string[] = [],
  initialText: Record<string, string> = {},
) => {
  const variant = question.variants.find(x => x.uuid === initialValue?.[0]);

  return makeFormElementState<VariantSelectionQuestion.SingleChoiceElementModel | null>(
    variant
      ? makeElementModels(variant, initialText[variant.uuid] || '')
      : null,
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
};

export const useState = (
  question: M.SingleChoiceQuestion,
  initialValue?: string[],
  initialText?: Record<string, string>,
) => {
  return useRef(makeState(question, initialValue, initialText)).current;
};
