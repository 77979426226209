import { useRef } from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

export const makeState = (
  question: M.VideoQuestion,
  initialValue: M.VideoFromHosting | null = null,
) => {
  return makeFormElementState(
    initialValue,
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
};

export const useState = (
  question: M.VideoQuestion,
  initialValue?: M.VideoFromHosting | null,
) => {
  return useRef(makeState(question, initialValue)).current;
};
