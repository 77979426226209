import { useRef } from 'react';

import * as M from 'types/serverModels';
import { makeFormElementState } from 'utils/FormState';
import { nonNull } from 'utils/validators';

import { questionIsRequired } from '../i18nSharedReferences';

const makeInitialState = (initialValue: string | null) => {
  if (initialValue === null) {
    return null;
  }
  const initialDate = new Date(initialValue);

  if (!isNaN(Number(initialDate))) {
    return initialDate;
  }
  return null;
};

export const makeState = (
  question: M.DateQuestion,
  initialValue: string | null = null,
) => {
  return makeFormElementState(
    makeInitialState(initialValue),
    !question.optional ? [nonNull(questionIsRequired)] : [],
  );
};

export const useState = (
  question: M.DateQuestion,
  initialValue?: string | null,
) => {
  return useRef(makeState(question, initialValue)).current;
};
