import React from 'react';

import { ReactComponent as CrossIcon } from 'shared/images/cross.svg';
import { block } from 'utils/classname';

import './style.scss';

export { makeLabel } from './Label';

const b = block('chip');

export type Variant = 'filled' | 'text';
export type Color = 'primary' | 'secondary' | 'light' | 'dark';
export type HoverEffect = 'none' | 'color-transition';
export type Size = 'xs' | 's' | 'm';

type Props = {
  Label: React.FC;
  variant?: Variant;
  color?: Color;
  hoverEffect?: HoverEffect;
  size?: Size;
  tabIndex?: React.HTMLAttributes<HTMLDivElement>['tabIndex'];
  disabled?: boolean;
  className?: string;
  onClick?(): void;
  onDelete?(): void;
};

function Chip({
  Label,
  variant = 'filled',
  color = 'light',
  hoverEffect = 'none',
  size = 's',
  tabIndex,
  disabled,
  className,
  onClick,
  onDelete,
}: Props) {
  const isInteractive =
    typeof onDelete === 'function' || typeof onClick === 'function';

  const handleClick = () => {
    onClick?.();
    onDelete?.();
  };

  const handleKeyDown: React.KeyboardEventHandler = event => {
    const element = event.target;

    const shouldClick = !event.repeat && event.code === 'Enter';

    if (element instanceof HTMLElement && shouldClick) {
      event.preventDefault();
      element.click();
    }
  };

  return (
    <div
      className={b(
        {
          variant,
          color,
          'hover-effect': hoverEffect,
          size,
          interactive: isInteractive,
          disabled,
        },
        [className],
      )}
      tabIndex={tabIndex}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
    >
      <Label />
      {typeof onDelete === 'function' && <CrossIcon className={b('icon')} />}
    </div>
  );
}

export const Component = React.memo(Chip);
