import React, { useCallback } from 'react';

import { Button, Modal } from 'components';
import i18nData from 'features/project/Constructor/i18n.json';
import { Classifications } from 'features/project/Constructor/subfeatures';
import { I18n } from 'services';
import { makeMappingUnitFromUnit } from 'utils/State';
import { block } from 'utils/classname';

import { classificationsUnit } from '../../../units/inputState';
import './style.scss';

const b = block('classifications-section-modal');

type Props = {};

export const isOpenUnit = Modal.makeIsOpenUnit();

export const stateUnit = Classifications.makeStateUnit();

const mappingUnit = makeMappingUnitFromUnit(stateUnit);

const Header = Modal.Header.makeFromTitle(
  () =>
    I18n.useText(i18nData).steps.projectAnnouncement.sections.classifications
      .modal.header,
);

function Subjects({}: Props) {
  const text =
    I18n.useText(i18nData).steps.projectAnnouncement.sections.classifications
      .modal;
  const classifications = classificationsUnit.useState();

  const handleAcceptButtonClick = useCallback(() => {
    const selectedUUIDs = Object.entries(mappingUnit.getState())
      .filter(([, { value }]) => value.units.value)
      .map(([key]) => key);
    classificationsUnit.setState(selectedUUIDs);
    isOpenUnit.setState(false);
  }, []);

  const handleCloseButtonClick = useCallback(() => {
    isOpenUnit.setState(false);
  }, []);

  return (
    <Modal.Component
      isOpenUnit={isOpenUnit}
      size="m"
      className={b()}
      Header={Header}
    >
      <Modal.Layout.Default.Component as="div">
        <div className={b('content')}>
          <Classifications.Component
            classification={process.env.RAZZLE_SUBJECT_CLASSIFIER}
            stateUnit={stateUnit}
            initialValues={classifications}
          />
        </div>
        <Modal.Footer.Layout.Controls.Component gap="m" justifyContent="start">
          <Button.Component type="button" onClick={handleAcceptButtonClick}>
            {text.button.accept}
          </Button.Component>
          <Button.Component
            variant="outlined"
            color="secondary"
            type="button"
            onClick={handleCloseButtonClick}
          >
            {text.button.cancel}
          </Button.Component>
        </Modal.Footer.Layout.Controls.Component>
      </Modal.Layout.Default.Component>
    </Modal.Component>
  );
}

export const Component = React.memo(Subjects);
