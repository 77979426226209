import React from 'react';
import { Link } from 'react-router-dom';

import { Device, Routing } from 'services';
import { block } from 'utils/classname';

import * as UnderlinedLabel from '../UnderlinedLabel';
import * as Mobile from './Mobile';
import './style.scss';

const b = block('main-menu-root-link-entry');

type Props = {
  label: string;
  href: string;
};

function LinkLabel({ label, href }: Props) {
  const isActive = Routing.useCurrentLocationIsMatchingTo(href);

  return (
    <UnderlinedLabel.Component underlined={isActive}>
      <Link className={b()} to={href}>
        {label}
      </Link>
    </UnderlinedLabel.Component>
  );
}

export const Component = React.memo(LinkLabel) as typeof LinkLabel;

export function makeRootLinkEntry(props: Props) {
  return () => {
    const device = Device.unit.useState();

    switch (device) {
      case 'desktop-s':
      case 'mobile':
        return <Mobile.Component href={props.href} label={props.label} />;

      default:
        return <Component {...props} />;
    }
  };
}
